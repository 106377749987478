import styled from "styled-components";

interface NavProps {
    isVisible: boolean;
}

export const Container = styled.div`
    height: 30px;
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #476C5E;

    .phone-HomeMenu{
        display: none;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: white; // Cor da caixa e letra !!
        color: #476C5E;
        padding-left: 5px;
        padding-right: 5px;
    }

    @media screen and (min-width: 320px) and (max-width: 1231px) {

        .phone-HomeMenu{
            display: flex;
            width: 95%;
        }
    }
`;

export const Nav = styled.nav<NavProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 100%;

    @media screen and (min-width: 320px) and (max-width: 1231px) {
        display: ${({ isVisible }) => isVisible ? 'flex' : 'none'};
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 31px;
        left: 3%;
        width: 94%;
        height: auto;
        border-bottom: 1px solid rgba(71, 108, 94, 0.5);
        border-top: 1px solid rgba(71, 108, 94, 0.5);

        ul{
            display: block;
            width: 100%;

            li {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 0;
                background-color: rgba(255, 255, 255, 0.8);
                border-bottom: 1px solid rgba(250, 250, 250, 0.1);

                a {
                    color: #476C5E;
                    font-size: 1.15em;
                    width: auto;
                    display: flex;
                    text-align: left;
                    height: auto;
                    padding: 25px 10%;
                }
            }
        }
    }
`;

export const Ul = styled.ul`
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const Li = styled.li`
    padding-right: 20px;
    display: flex;
    align-items: center;
    background-color: #476C5E;

    a {
        height: 30px;
        text-decoration: none;
        font-size: 15px;
        color: white;
        position: relative;
        display: flex;
        align-items: center;
        padding-right: 10px;
        padding-left: 10px;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: 4px;
            left: 0;
            background-color: white;
            transform: scaleX(0);
            transition: transform 0.3s ease;
        }

        &:hover::before {
            transform: scaleX(1);
        }
    }

    @media screen and (min-width: 320px) and (max-width: 1231px) {
        a{ 
            font-size: 14px;
        }
    }
`;