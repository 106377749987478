import React, { useState, useRef } from 'react';

import * as C from './style';
import MenuIcon from '../../components/Header/HeaderMenu/assets/menu.png';

export const HomeMenu = () => {
    const [navVisible, setNavVisible] = useState(false);
    const navRef = useRef(null);

    const toggleNavVisibility = () => {
        setNavVisible(!navVisible);
    };

    return (
        <C.Container>
            <div className='phone-HomeMenu'>
                <p><b>Escolha uma página</b></p>
                <img src={MenuIcon}
                    alt="Menu com diversas opções de detalhes e informações sobre a BioTechPar e Harpia"
                    width={20} 
                    onClick={toggleNavVisibility}/>
            </div>

            <C.Nav isVisible={navVisible}>
                <C.Ul>
                    <C.Li><a href="https://harpiarep.com.br/microscopios-confocal/" target='_blank' rel='noopener noreferrer'><b>Microscopia Confocal e Super Resolução</b></a></C.Li>
                    <C.Li><a href="https://harpiarep.com.br/microscopia-optica/" target='_blank' rel='noopener noreferrer'><b>Microscopia Óptica</b></a></C.Li>
                    <C.Li><a href="https://harpiarep.com.br/crio-microscopia-eletronica/" target='_blank' rel='noopener noreferrer'><b>Crio Microscopia Eletrônica</b></a></C.Li>
                    <C.Li><a href="https://harpiarep.com.br/microscopios-eletronicos/" target='_blank' rel='noopener noreferrer'><b>Microscópios Eletrônicos</b></a></C.Li>
                    <C.Li><a href="https://harpiarep.com.br/preparacao-de-amostras/" target='_blank' rel='noopener noreferrer'><b>Preparação de Amostras</b></a></C.Li>
                    <C.Li><a href="https://harpiarep.com.br/blog/" target='_blank' rel='noopener noreferrer'><b>Blog</b></a></C.Li>
                </C.Ul>
            </C.Nav>
        </C.Container>
    );
}