import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 100px;

    @media screen and (min-width: 320px) and (max-width: 760px) {
        width: 100%;
        height: auto;
        padding-top: 0;
    }
`;

export const Title = styled.div`
    text-align: center;
    margin-top: -80px;
    margin-bottom: 80px;
    color: #476C5E;

    @media screen and (min-width: 320px) and (max-width: 760px) {
        margin-top: -140px;
    }
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    justfiy-content: center;
    align-items: center;
    padding-bottom: 110px;
    
    img {
        justify-content: center;
    }

    .whatsIcon-phone{
        width: 12px;
    }

    p {
        width: 700px;
        line-height: 2.0;
        font-size: 14px;
        padding-top: 50px;
        text-align: justify;
    }

    .mid-text-phone{
        text-align: center;
    }

    .whatsIcon {
        padding-left: 10px;
    }

    .representedSinapse {
        padding-top: 20px;
    }

    a {
        text-decoration: none;
        font-weight: bold;
        font-size: 16px;
        color: white;
        display: inline-block;
        width: 250px;
        padding-left: 30px;
        margin-left: 10px;
        background-color: #476C5E;
        border: 1px solid #555555;
        border-radius: 10px;
        box-shadow: 0px 2px 4px rgba(0, 123, 255, 0.2);
        transition: transform 0.3s ease;
    }

    a:hover {
        text-decoration: underline;
        transform: scale(1.05);
    }
    
    a:visited {
        color: white;
    }

    @media screen and (min-width: 320px) and (max-width: 760px) {
        width: 100%;
        padding-bottom: 150px;
        margin-top: 0px;

        img {
            width: 300px;
        }
        
        p {
            font-size: 12px;
            width: 90%;
            padding-top: 20px;
            text-align: justify;
        }

        .mid-text-phone{
            text-align: center;
        }

        .representedSinapse {
            width: 90%;
        }
    }

    @media screen and (min-width: 426px) and (max-width: 760px) {
        .mid-text-phone {
            text-align: left;
        }
    }

    @media screen and (min-width: 761px) and (max-width: 1024px) {
        .representedSinapse{
            width: 90%;
        }
    }
`;