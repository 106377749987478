import * as C from './style';

import Dna from './assets/dnaGtx.png';
import harpia from './assets/Harpia.jpg';
import biolinker from './assets/BioLinker.png';
import sinapse from './assets/sinapse.jpg';
import represented from './assets/representedSinapse.jpg';
import whatsIcon from '../../assets/icons/Pictogrammers-Material-Whatsapp.512.png';

export const RepEnterprises = () => {
    return(
        <C.Container>
            <C.Title>
                <h1>Empresas Representadas</h1>
            </C.Title>
            <C.Body>
                <img src={harpia} alt="Logo da empresa Harpia" height={335}/>
                <p>
                    Fundada em 2015 por profissionais com mais de 11 anos de 
                    experiência em microscopia óptica de luz e histologia em 
                    ciências da vida. Nosso objetivo é auxiliar pesquisadores, 
                    alunos e técnicos de universidades e centros de pesquisa na 
                    comunicação com fornecedores de instrumentação científica. 
                    Nosso portfólio inclui equipamentos de marcas líderes de mercado 
                    como Thermo Fisher Scientific e Leica Microsystems, abrangendo 
                    microscópios ópticos de luz e confocal, microscópios eletrônicos 
                    de varredura e transmissão, além de equipamentos para preparação 
                    de amostras em microscopia de luz e eletrônica.

                </p>
                <p>
                    <a href="https://harpiarep.com.br/" target='_blank' title='Visite o site da Harpia'>Visite o site da Harpia</a>
                </p>
            </C.Body>

            <C.Body>
                <img src={biolinker} alt="Logo da empresa BioLinker" height={335}/>
                <p>
                    BioLinker | Startup de Produção de Proteína e Biologia Sintética
                    Biolinker: feito por cientistas para cientistas!
                    Na BioLinker OTIMIZAMOS BIOPROCESSOS E AUMENTAMOS A TAXA DE SUCESSO 
                    NA PRODUÇÃO DO SEU BIOINSUMO. Bio significa vida, e tudo que é vivo 
                    tem DNA e somos os mestres em decodificar o código da vida e transformar 
                    em ingredientes que transformam, colorem, melhoram a saúde animal, 
                    vegetal e humana e ajudam o planeta.

                    A BioLinker é proprietária da plataforma SOL que acelera e reduz o risco 
                    do seu BIOPROCESSO, tornando realidade o uso de biologia sintética no seu segmento.

                    A BioLinker também é uma entre as 10 ÚNICAS EMPRESAS no mundo que 
                    comercializam seu próprio kit de síntese livre de células para proteínas 
                    difíceis de expressar. Além de nossas tecnologias inovadoras, temos proteína
                    a pronta entrega para uso em seu dia a dia.

                    Aprendemos com a natureza e usando fábricas de organismos vivos, criamos 
                    ingredientes biológicos para um mundo melhor, apoiando a inovação 
                    científica e transformando a economia"

                </p>

                <p className='mid-text-phone'>
                    <b>Conheça um pouco mais sobre a BioLinker: </b> <br />
                    <a href="https://www.youtube.com/watch?v=M2aubSWxNzg" target='_blank'> Vídeo sobre a BioLinker</a>
                </p>

                <p className='mid-text-phone'>
                    <b>Portifólio de serviços:</b> <br />
                    <a href="/BioLinker-Services.pdf" download> Baixar PDF de serviços</a>
                    <br /> <br />
                    <b>Portifólio de produtos:</b> <br />
                    <a href="/BioLinker-Products.pdf" download> Baixar PDF de produtos</a>
                </p>

                <p className='mid-text-phone'>
                    <b>Faça seu orçamento clicando aqui:</b> <br />
                    <a className='whatsIcon' href="https://wa.me/5541992881987" target='_blank' rel='noopener noreferrer'><img className='whatsIcon-phone' src={whatsIcon} alt="Número de WhatsApp" width='12px'/> +55 (41) 99288-1987</a>
                </p>
            </C.Body>

            <C.Body>
                <img src={sinapse} alt="Logo da empresa Sinapse" height={335}/>
                <p>
                    SINAPSE Biotecnologia | Produtos para biologia molecular, celular e proteômica
                    Desde o início de nossas atividades em 1998, temos conquistado a confiança de nossos 
                    clientes, que são grandes nomes da ciência brasileira, estudantes de mestrado e 
                    doutorado, instituições governamentais e empresas privadas.
                    A credibilidade que temos hoje no mercado é fruto do pleno atendimento das expectativas de nossos clientes. 
                    Nós temos bons prazos de entrega, assistência técnica adequada, logística especializada e preços competitivos.
                    A Sinapse sempre oferece ao pesquisador acesso a novas tecnologias, soluções inovadoras e produtos de última 
                    geração, pois representamos empresas de ponta do mercado mundial.

                </p> <br />
                <img className='representedSinapse' src={represented} alt="Empresas representadas pela Sinapse" />

                <p className='mid-text-phone'>
                    <b>Clique aqui e confira os produtos: </b>
                    <a href="/Sinapse_Products.pdf" download> PDF BiotechPar - Sinapse</a>
                </p>

                <p className='mid-text-phone'>
                    <b>Faça seu orçamento clicando aqui:</b>
                    <a className='whatsIcon' href="https://wa.me/5541992881987" target='_blank' rel='noopener noreferrer'><img className='whatsIcon-phone' src={whatsIcon} alt="Número de WhatsApp" width='12px'/> +55 (41) 99288-1987</a>
                </p>
            </C.Body>
            <C.Body>
                <img src={Dna} alt="Logo da empresa DNA GTx" width={700}/>
                <p>
                    A DNA GTx Bioinformatics é uma empresa de Bioinformática
                    e Biotecnologia que fornece soluções inovadoras e 
                    personalizadas para desafios biológicos, em todos os
                    organismos. Sua visão está centrada na abordagem de
                    questões-chave nas esferas da saúde, agricultura e 
                    ambiente e a missão é melhorar a qualidade de vida e 
                    promover o desenvolvimento sustentável.
                    Com uma abordagem humanizada, a empresa aproveita as 
                    capacidades da genômica e da Bioinformática e desenvolve 
                    um portfólio projetado para atender às necessidades 
                    dos clientes englobando os seguintes serviços:
                </p>
            </C.Body>
        </C.Container>
    );
}